<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors">
        <div class="preview">
            <input type="text" disabled :value="editorValue.code" />
            <button class="open-button" @click="open" type="button" :disabled="loading">
                <div class="loading" v-if="loading">
                    <i class="mdi mdi-loading" />
                </div>
                <i class="mdi mdi-microsoft-visual-studio-code" v-else />
            </button>
        </div>
        <div class="code-editor-layout" v-if="showEditor">
            <splitpanes horizontal class="default-theme split-view-base" @resize="onResize">
                <pane size="80">
                    <splitpanes @resize="onResize">
                        <pane size="60">
                            <div class="toolbar">
                                <div class="toolbar__title">
                                    <i class="mdi mdi-nodejs" />
                                    <span>Editor</span>
                                </div>
                                <div class="toolbar__actionbar actionbar">
                                    <div class="actionbar__right">
                                        <div
                                            role="button"
                                            class="actionbar__action icon"
                                            @click="() => changeEditorFontSize(-1)"
                                            title="Decrease font size"
                                        >
                                            <i class="mdi mdi-format-font-size-decrease" />
                                        </div>
                                        <div
                                            role="button"
                                            class="actionbar__action icon"
                                            @click="() => changeEditorFontSize(1)"
                                            title="Increase font size"
                                        >
                                            <i class="mdi mdi-format-font-size-increase" />
                                        </div>
                                        <div
                                            role="button"
                                            class="actionbar__action icon"
                                            @click="() => callQuickOptions(codeEditor)"
                                            title="Increase font size"
                                        >
                                            <i class="mdi mdi-cog-outline" />
                                        </div>
                                    </div>
                                    <div class="actionbar__left">
                                        <div
                                            role="button"
                                            class="actionbar__action"
                                            :disabled="loading"
                                            @click="save"
                                            title="Ctrl+S"
                                        >
                                            <i class="mdi mdi-content-save-outline" />
                                            <span>Apply</span>
                                        </div>
                                        <div
                                            role="button"
                                            class="actionbar__action"
                                            :disabled="loading"
                                            @click="saveAndExit"
                                        >
                                            <i class="mdi mdi-content-save-outline" />
                                            <span>Apply & Exit</span>
                                        </div>
                                        <div
                                            role="button"
                                            class="actionbar__action"
                                            :disabled="loading"
                                            @click="toggleTest"
                                        >
                                            <i :class="`mdi mdi-flask-empty-outline ${isTest ? `active-icon` : ''}`" />
                                            <span :class="{ 'active-text': isTest }">Test</span>
                                            <i
                                                :class="`mdi mdi-chevron-double-${isTest ? `right` : `left`} ${
                                                    isTest ? `active-chevron-icon` : ``
                                                }`"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="monaco-editor" ref="codeEditor"></div>
                        </pane>
                        <pane v-if="isTest" size="40">
                            <div class="toolbar">
                                <div class="toolbar__actionbar actionbar">
                                    <div class="actionbar__right">
                                        <div
                                            role="button"
                                            class="actionbar__action icon"
                                            @click="() => callQuickOptions(testEditor)"
                                            title="Increase font size"
                                        >
                                            <i class="mdi mdi-cog-outline" />
                                        </div>
                                        <div
                                            role="button"
                                            class="actionbar__action green"
                                            @click="runTests"
                                            title="Alt+Enter"
                                            :disabled="loading"
                                        >
                                            <i class="mdi mdi-play" />
                                            <span>Run</span>
                                        </div>
                                    </div>
                                    <div class="actionbar__left">
                                        <div
                                            role="button"
                                            class="actionbar__action icon"
                                            @click="close"
                                            title="Close (Esc)"
                                        >
                                            <i class="mdi mdi-window-close" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="monaco-editor" ref="testEditor"></div>
                        </pane>
                    </splitpanes>
                </pane>
                <pane v-if="isTest" size="20">
                    <div class="console">
                        <div class="console__toolbar toolbar">
                            <div class="toolbar__title">
                                <i class="mdi mdi-console" />
                                <span>Console</span>
                            </div>
                            <div class="toolbar__actionbar actionbar">
                                <div class="actionbar__right">
                                    <div role="button" class="actionbar__action" @click="clearConsole">
                                        <i class="mdi mdi-close-circle-outline" />
                                        <span>Clear</span>
                                    </div>
                                </div>
                                <div class="actionbar__left"></div>
                            </div>
                        </div>
                        <div class="console__output" ref="console">
                            <div
                                v-for="log in sortedConsoleLogs"
                                :key="log.id"
                                class="console__log"
                                :class="[log.type]"
                            >
                                <div class="console__log__time">
                                    {{ formatLogTime(log.datetime) }}
                                </div>
                                <!-- <template v-if="!log.json"> -->
                                <div class="console__log__value">
                                    <div v-html="log.value"></div>
                                    <json-viewer v-if="log.json" class="json-viewer">{{ log.json }}</json-viewer>
                                </div>
                                <!-- </template>
								<template v-else>
								<div class="console__log__value">
									<json-viewer>{{ log.value }}</json-viewer>
								</div>
								</template> -->
                            </div>
                        </div>
                    </div>
                </pane>
            </splitpanes>
        </div>
    </BaseControl>
</template>

<script>
import _ from "lodash"
import md5 from "js-md5"
import moment from "moment"
import monacoLoader from "@monaco-editor/loader"
import { Pane, Splitpanes } from "splitpanes"
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import { API_BASE_URL } from "@Platon/const"
import "splitpanes/dist/splitpanes.css"
import "@Platon/external/json-viewer"

const monacoEditorFontSizeLSKey = "monaco-editor-font-size"
let monacoEditorFontSize = parseInt(window.localStorage.getItem(monacoEditorFontSizeLSKey))
if (!monacoEditorFontSize) {
    monacoEditorFontSize = 18
    window.localStorage.setItem(monacoEditorFontSizeLSKey, 18)
}

const monacoEditorDefaultOptions = {
    language: "javascript",
    fontSize: monacoEditorFontSize,
    automaticLayout: false, // for instant resize, may occure perfomance
    theme: "vs-dark",
    minimap: {
        enabled: true
    }
}

export default {
    name: "MonacoEditorControl",
    components: { Pane, Splitpanes, BaseControl },
    mixins: [InputControlMixin, ValidationMixin],
    props: {
        value: [String, Object],
        options: Object
    },
    data() {
        return {
            loading: false,
            checksum: null,
            codeEditor: null,
            testEditor: null,
            showEditor: false,
            console: [],
            isTest: localStorage.getItem("isTestCase") === "true" || false
        }
    },

    methods: {
        toggleTest() {
            this.isTest = !this.isTest
        },
        clearConsole() {
            this.console = []
        },
        onResize: _.throttle(function () {
            this.codeEditor?.layout()
            this.testEditor?.layout()
        }, 50),
        async save() {
            if (this.loading) return

            let { code, test } = this.getEditorValue()
            let es5 = await this.transpileToES5(code)

            if (!es5) return false

            this.checksum = md5(code + test)
            this.$emit("input", JSON.stringify({ code, test, es5 }))

            return true
        },
        async saveAndExit() {
            if (this.loading) return

            if (await this.save()) {
                await this.close()
            }
        },
        async transpileToES5(js, returnHack = true) {
            this.loading = true
            const babel = await import("@babel/standalone")
            const minify = await import("babel-preset-minify")
            const transformObjectSpread = await import("@babel/plugin-proposal-object-rest-spread")
            const transformSpread = await import("@babel/plugin-transform-spread")

            this.loading = false

            let code = null
            let jsCode = !returnHack ? js : `(function(){\n${js}\n})();`

            try {
                code = babel.transform(jsCode, {
                    presets: ["es2015", [minify, { builtIns: false }]],
                    plugins: [transformObjectSpread, transformSpread],
                    comments: false
                }).code
            } catch (error) {
                this.logger({
                    type: "error",
                    value: error
                })
            }

            return !returnHack ? code : code.replace('"use strict";', "return ")
        },
        getEditorValue() {
            return {
                code: this.codeEditor?.getValue(),
                test: this.testEditor?.getValue()
            }
        },
        open() {
            if (this.loading) return

            this.loading = true
            monacoLoader.init().then((monaco) => {
                this.showEditor = true
                this.loading = false
                this.checksum = md5(this.editorValue.code + this.editorValue.test)
                this.$nextTick(() => {
                    this.codeEditor = monaco.editor.create(
                        this.$refs.codeEditor,
                        _.merge({}, monacoEditorDefaultOptions, { value: this.editorValue.code })
                    )

                    if (this.$refs.testEditor) {
                        this.initTestEditor()
                    }

                    this.codeEditor.focus()
                })
            })
        },
        async close() {
            let { code, test } = this.getEditorValue()
            if (this.checksum !== md5(code + test)) {
                if (confirm("You have unsaved changes in editor. Would you like to save them?")) {
                    if (!(await this.save())) return
                }
            }
            this.codeEditor?.getModel()?.dispose()
            this.testEditor?.getModel()?.dispose()
            this.showEditor = false
        },
        formatLogTime(datetime) {
            return moment(datetime / 1e6).format("HH:mm:ss.SSS")
        },
        async runTests() {
            if (this.loading) return
            this.loading = true

            let { code, test } = this.getEditorValue()
            code = await this.transpileToES5(code)
            test = await this.transpileToES5(test, false)
            if (!code || !test) return

            this.logger("🧪 Running tests...")
            const requestDate = Date.now() * 1e6

            $api.post(
                "/utils/js_eval_run",
                { code, test },
                {
                    baseURL: API_BASE_URL
                }
            )
                .then(({ data }) => {
                    const { tests, logs } = data

                    for (let test of tests) {
                        this.logger({
                            value: `${test.passed ? "✅" : "❌"} ${test.name}`,
                            datetime: requestDate + test.evaluation_time,
                            json: JSON.stringify({ " result": test.result })
                        })
                        this.logger(
                            test.logs.map((log) => ({
                                ...log,
                                value: `󠀠<span class="invisible">👉</span> ${log.value}`,
                                datetime: requestDate + log.evaluation_time
                            }))
                        )
                    }

                    this.logger(
                        logs.map((log) => ({
                            ...log,
                            datetime: requestDate + log.evaluation_time
                        }))
                    )

                    const passedTests = tests.reduce((total, test) => (test.passed ? total + 1 : total), 0)
                    this.logger(`🏁 [${passedTests}/${tests.length}] Tests done.`)
                })
                .catch((e) => {
                    if (e.toJSON().message === "Network Error") {
                        this.logger({
                            type: "error",
                            value: "[NETWORK ERROR] Server not responding. Check your internet connection."
                        })
                    } else if (e.response) {
                        this.logger({
                            type: "error",
                            value: `[ERROR ${e.response.status}] ${e.message}\n${JSON.stringify(e.response.data)}`
                        })
                    } else {
                        this.logger({
                            type: "error",
                            value: "[UNKNOWN ERROR]",
                            json: JSON.stringify({ " error": e })
                        })
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        logger(log) {
            const createLog = (log) => {
                let logDefault = {
                    value: "",
                    type: "log",
                    datetime: Date.now() * 1e6
                }

                this.console.push({
                    ...logDefault,
                    id: Symbol("log_id"),
                    ...log
                })
            }
            if (Array.isArray(log)) {
                for (let item of log) createLog(item)
            } else if (log && typeof log === "object") {
                createLog(log)
            } else if (typeof log === "string") {
                createLog({ value: log })
            }
        },
        keyTriggers(event) {
            if (event.key === "Escape") {
                this.close()
            }

            if (event.key === "s" && event.ctrlKey) {
                event.preventDefault()
                this.save()
            }

            if (event.key === "Enter" && event.altKey) {
                event.preventDefault()
                this.runTests()
            }
        },
        changeEditorFontSize(amount) {
            if (!this.codeEditor || !this.testEditor) return
            let currentFontSize = parseInt(window.localStorage.getItem(monacoEditorFontSizeLSKey))
            currentFontSize += amount
            window.localStorage.setItem(monacoEditorFontSizeLSKey, currentFontSize)

            let newConfig = {
                fontSize: currentFontSize
            }
            this.codeEditor.updateOptions(newConfig)
            this.testEditor.updateOptions(newConfig)
        },
        callQuickOptions(editor) {
            if (!editor) return
            editor.focus()
            editor.getAction("editor.action.quickCommand").run()
        },
        initTestEditor() {
            this.$nextTick(() => {
                this.testEditor = monaco.editor.create(
                    this.$refs.testEditor,
                    _.merge({}, monacoEditorDefaultOptions, { value: this.editorValue.test })
                )
            })
        }
    },

    mounted() {
        window.addEventListener("resize", this.onResize)
        window.addEventListener("keydown", this.keyTriggers)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize)
        window.removeEventListener("keydown", this.keyTriggers)
        this.codeEditor?.getModel()?.dispose()
        this.testEditor?.getModel()?.dispose()
    },
    computed: {
        editorValue() {
            let value = null
            switch (typeof this.value) {
                case "object":
                    if (!this.value) break
                    if (this.value.type && this.value.type === "json") {
                        value = JSON.parse(this.value.value)
                    } else if ("code" in this.value && "test" in this.value) {
                        value = { ...this.value }
                    }
                    break
                case "string":
                    try {
                        value = JSON.parse(this.value)
                    } catch (e) {
                        value = { code: this.value, test: "" }
                    }
                    break
            }
            return value || { code: "", test: "" }
        },
        sortedConsoleLogs() {
            return this.console.sort((a, b) => a.datetime - b.datetime)
        }
    },
    watch: {
        console() {
            const $console = this.$refs["console"]
            this.$nextTick(() => {
                if ($console) $console.scrollTop = $console.scrollHeight
            })
        },
        isTest(val) {
            if (!this.testEditor) {
                this.initTestEditor()
            }
            localStorage.setItem("isTestCase", val)
        }
    }
}
</script>

<style lang="scss" scoped>
$toolbar-height: 40px;

.preview {
    display: flex;

    input {
        flex-grow: 1;
        background: #1e1e1e;
        color: #949494;
        border: 0;
        height: 35.75px;
        line-height: 35.75px;
        border-radius: 4px 0 0 4px;
        padding: 0 10px;
    }

    .open-button {
        background: #303030;
        border-radius: 0 4px 4px 0;
        border: 0;
        color: #fff;
        height: 35.75px;
        line-height: 35.75px;
        padding: 0 14px;

        &[disabled] {
            background: #666;
            cursor: default;
        }

        @keyframes loading {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        .loading {
            transform-origin: center;
            animation: loading 0.6s ease-in infinite;
        }
    }
}

.code-editor-layout {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #303030;
    color: #949494;
    display: flex;

    .monaco-editor {
        width: 100%;
        height: calc(100% - $toolbar-height);
    }
}

.actionbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__right,
    &__left {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__action {
        padding: 0 8px 0 4px;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
        border: 0;
        transition: background 0.2s ease;
        display: flex;
        align-items: center;

        .mdi {
            font-size: 18px;
            display: inline-block;
            margin-right: 2px;
        }

        .active-icon {
            color: #1995e7;
        }

        .active-text {
            color: #fff;
        }

        .active-chevron-icon {
            color: #fff;
        }

        &.icon {
            padding-right: 4px;
        }

        &[disabled] {
            opacity: 0.5;
        }

        &:hover {
            background: rgba(#fff, 0.05);
        }

        &.green .mdi {
            color: #52c581;
        }

        &.red .mdi {
            color: #ec3810;
        }
    }
}

.toolbar {
    height: $toolbar-height;
    background: #303030;
    padding: 0 16px;
    display: flex;
    gap: 10px;
    align-items: center;

    &__title {
        cursor: default;
        user-select: none;
        height: $toolbar-height;
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin-right: 10px;

        span {
            color: #fff;
        }

        .mdi {
            font-size: 18px;
            color: #1995e7;
            margin-right: 6px;
        }
    }

    &__actionbar {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.console {
    background: #232323;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    &__toolbar {
        padding: 10px 10px;
        display: flex;
        gap: 10px;
        background: #1e1e1e;
    }

    &__output {
        flex-grow: 1;
        overflow-y: scroll;
        padding: 10px;

        &::-webkit-scrollbar {
            width: 16px;
        }

        &::-webkit-scrollbar-track {
            background: #1e1e1e;
            border-left: 1px solid #444;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0;
            border-left: 1px solid #444;
        }

        .console__log:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    &__log {
        display: flex;
        gap: 10px;
        font-family: Consolas, "Courier New", monospace;

        &__time {
            width: 100px;
            opacity: #444;
        }

        &__value {
            display: flex;
            gap: 10px;
            width: calc(100% - 110px);
            white-space: pre-wrap;
            color: #fff;
        }

        &.error &__value {
            color: #ec3810;
        }

        &.warn &__value {
            color: #ff9900;
        }
    }
}

.split-view-base {
    height: 100vh;

    ::v-deep .splitpanes__splitter {
        background: #303030;
        border-color: #303030;

        &:after,
        &:before {
            display: none;
        }
    }
}
</style>

<style lang="scss">
.console {
    .gray {
        color: rgb(193, 154, 186);
    }

    .invisible {
        opacity: 0;
    }
}

json-viewer {
    --background-color: transparent;
}
</style>
